import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TaxTariffService {

  constructor(
  	private apiService: ApiService
  ) { }


  getTaxonTaxTariffs(merchant_id: number) {
    const access_token = null;
    return this.apiService.get("api/v1/taxon_tax_tariffs?q[taxon_catalog_owner_id_eq]=" + merchant_id, access_token);
  }

  getTaxTariffs() {
    const access_token = null;
    return this.apiService.get("api/v1/tax_tariffs", access_token);
  }
  
  createTaxonTaxTariff(taxon_tax_tariff: any) {
    const access_token = null;
    const payload = '{ "taxon_tax_tariff": ' + JSON.stringify(taxon_tax_tariff) + '}';
    return this.apiService.post('api/v1/taxon_tax_tariffs', payload,access_token);
  }

  deleteTaxonTaxTariff(taxon_tax_tariff_id) {
    const access_token = null;
    return this.apiService.delete('api/v1/taxon_tax_tariffs/' + taxon_tax_tariff_id, access_token);
  }

  getProductTaxTariffs(merchant_id: number) {
    const access_token = null;
    return this.apiService.get("api/v1/product_tax_tariffs?q[product_catalog_owner_id_eq]=" + merchant_id, access_token);
  }

  createProductTaxTariff(product_tax_tariff: any) {
    const access_token = null;
    const payload = '{ "product_tax_tariff": ' + JSON.stringify(product_tax_tariff) + '}';
    return this.apiService.post('api/v1/product_tax_tariffs', payload,access_token);
  }

  deleteProductTaxTariff(product_tax_tariff_id) {
    const access_token = null;
    return this.apiService.delete('api/v1/product_tax_tariffs/' + product_tax_tariff_id, access_token);
  }
}
