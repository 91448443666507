import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

@Injectable()
export class PrintService {

	constructor(
		private apiService: ApiService,
		private userService: UserService
	) {}

	getStatus(merchant_id, kiosk_id = null, printer_id = null): Observable<any> {

		let uri = `api/v1/cloudprnt/status?merchant_id=${merchant_id}&kiosk_id=${kiosk_id}`
		if (printer_id !== null) { 
			uri += `&printer_id=${printer_id}` 
		};
		return this.apiService.get(uri, this.userService.access_token);
	
	}

	getPrintJobStatus(print_job_id): Observable<any> {
		return this.apiService.get('api/v1/cloudprnt/print_job_status?print_job_id=' + print_job_id, this.userService.access_token);
	}

}
