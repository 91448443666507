<div class="main p-3">

<h2 mat-dialog-title>{{ data.message }}</h2>

<mat-dialog-actions [align]="'end'">
  <button mat-button
        style="font-size:1rem"
  		  (click)="close()"
        tabindex="-1">
    {{ 'cancel' | translate }}
  </button>
  <button mat-button
        [color]="merchantService.pos ? '' : 'primary'"
        [ngStyle]="{'background-color': merchantService.merchant?.primary_color ? merchantService.merchant.primary_color : ''}"
  		  (click)="confirm()"
        [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
        tabindex="-1">{{ 'yes' | translate }}
  </button>
</mat-dialog-actions>

</div>
