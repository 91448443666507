import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PriceModifierService {

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  // 'q[catalog_owner_id_eq]': $scope.merchant.id,
  // 'q[catalog_owner_type_eq]': 'Merchant'

  loadOrderPriceModifiers(merchant_id) {
    return this.apiService.get('api/v1/order_price_modifiers?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant', this.userService.access_token)
  }

  loadProductPriceModifiers(merchant_id) {
    return this.apiService.get('api/v1/product_price_modifiers?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant', this.userService.access_token)
  }

  createOrderPriceModifier(orderPriceModifier) {
    let params = {
      order_price_modifier: orderPriceModifier
    }
    return this.apiService.post('api/v1/order_price_modifiers', params, this.userService.access_token)
  }

  createProductPriceModifier(productPriceModifier) {
    let params = {
      product_price_modifier: productPriceModifier
    }
    return this.apiService.post('api/v1/product_price_modifiers', params, this.userService.access_token)
  }

  updateOrderPriceModifier(orderPriceModifier) {
    let params = {
      order_price_modifier: orderPriceModifier
    }
    return this.apiService.put('api/v1/order_price_modifiers/' + orderPriceModifier.id, params, this.userService.access_token)
  }

  updateProductPriceModifier(productPriceModifier) {
    let params = {
      product_price_modifier: productPriceModifier
    }
    return this.apiService.put('api/v1/product_price_modifiers/' + productPriceModifier.id, params, this.userService.access_token)
  }

  deleteOrderPriceModifier(orderPriceModifier) {
    return this.apiService.delete('api/v1/order_price_modifiers/' + orderPriceModifier.id, this.userService.access_token)
  }

  deleteProductPriceModifier(productPriceModifier) {
    return this.apiService.delete('api/v1/product_price_modifiers/' + productPriceModifier.id, this.userService.access_token)
  }
}
